import React from 'react'
import SbEditable from 'storyblok-react'
import makeStyles from '@material-ui/styles/makeStyles'
import { Box } from '@material-ui/core'
import font from '@helpers/font'
import classNames from 'classnames'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    color: ({ color }) =>
      color?.color ? color.color : theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    padding: '.3rem 0',
  },
  icon: {
    margin: '0 10px',
    width: ({ iconSize }) => `${iconSize || 20}px`,
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  text: ({ fontSize }) => ({
    ...makeResponsiveFontSizes(fontSize),
  }),
  textInherit: {
    fontSize: 'inherit',
  },
}))

const ChecklistItem = ({ blok, fontSize, icon, iconSize, isInChecklist }) => {
  const { color, customIcon, itemText, noIcon } = blok
  const classes = useStyles({ color, fontSize, icon, iconSize })

  return (
    <SbEditable content={blok}>
      <Box className={classes.root}>
        {noIcon ? (
          <Box className={classes.icon}></Box>
        ) : (
          <img
            alt="checklist bullet"
            className={classes.icon}
            src={customIcon?.filename ? customIcon.filename : icon.filename}
          />
        )}
        <Box
          className={classNames(
            classes.bold,
            classes[isInChecklist ? 'text' : 'textInherit']
          )}
        >
          {itemText}
        </Box>
      </Box>
    </SbEditable>
  )
}

export default ChecklistItem
